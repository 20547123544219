import { IntegrationSystems } from 'shared/data-layer/integration';

export const UNRESTRICTED_INTEGRATIONS = [
  IntegrationSystems.LinkedIn.toString(),
  IntegrationSystems.PocketRss.toString(),
]

export const PLAN_BASED_INTEGRATIONS = [
  IntegrationSystems.API.toString(),
  IntegrationSystems.Fonteva.toString(),
  IntegrationSystems.GrowthZone.toString(),
  IntegrationSystems.HigherLogicMagnetMail.toString(),
  IntegrationSystems.Imis.toString(),
  IntegrationSystems.Impexium.toString(),
  IntegrationSystems.Informz.toString(),
  IntegrationSystems.Instagram.toString(),
  IntegrationSystems.Nimble.toString(),
  IntegrationSystems.PersonifyMCPro.toString(),
  IntegrationSystems.YourMembership.toString(),
  IntegrationSystems.Zoom.toString(),
]

export const isUnrestrictedIntegration = (integrationSystemsName: string) => {
  return UNRESTRICTED_INTEGRATIONS.indexOf(integrationSystemsName) !== -1
}
export const isPlanBasedIntegration = (integrationSystemsName: string) => {
  return PLAN_BASED_INTEGRATIONS.indexOf(integrationSystemsName) !== -1
}
