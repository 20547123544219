import * as Actions from 'components/banner/actions'
import { BannerType } from 'components/banner/constants'
import { HeaderComponent } from 'components/header/component'
import { ComingSoon } from 'components/icons/coming'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as GenericRedux from 'generic/genericRedux'
import { isTruthy, noMoreUpgradeAvailable } from 'generic/utility'
import * as GA from 'google-analytics'
import { orderBy } from 'lodash'
import isNil from 'lodash/isNil'
import React from 'react'
import {
  CommunitySystem,
  getCommunityIntegrations,
  IntegrationSections,
  IntegrationSystems
} from 'shared/data-layer/integration'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { DEFAULT_PARTNER_IDENTIFIER, UNLIMITED_VALUE } from 'shared_server_client/constants'
import { formatDateTimeForLambda } from 'shared_server_client/dates'
import * as BillingPlan from 'shared_server_client/types/billing_plan'
import {BillingPlanDetailCode, canAccessPlanFeature} from 'shared_server_client/types/billing_plan'
import { enterpriseUsage, UsageStats } from 'shared_server_client/types/usage_stats'
import { stopPropagationThen } from '../../link'
import { CommunitySystemStatus, ENTERPRISE_UPGRADE_MAIL_TO, INTEGRATIONS_CONFIG_URL } from '../constants'
import '../styles.css'
import * as Integrations from './integrations'
import { IntegrationStatus } from './integrations'
import * as Utils from './utils'
import { UsageMeterComponent } from '../../../shared/usage-meter-component'
import { Roles } from '../../../shared/constants'
import { Upgrade } from '../../icons/upgrade'
import { MenuItem, TabMenu } from '../../tab-menu/component'
import { IntegrationTabMenus } from '../types'
import { DashboardMenuOption } from '../../dashboard-menu/constants'

const formatDisplay = (text: string, status: boolean): string => status ? text : `Not ${text}`

interface FlagProps {
  text?: string,
  status: boolean,
  click?: any,
  className?: string,
}

export const Flag = ({text, status, click, className}: FlagProps) => {
  return <div className={status ? `rectangle-flag-on ${className}` : `rectangle-flag-off  ${className}`}>
    <div onClick={click} className={status ? 'text-flag-on' : 'text-flag-off'}>
      {formatDisplay(text, status)}
    </div>
  </div>
}

type SettingsIntegrationsProps = GenericRedux.DatasetComponentProps<CommunitySystem>

interface SettingsIntegrationsState {
  data: any[],
  activeIntegrations: any[],
  availableIntegrations: any[],
  enterpriseIntegrations: any[],
  plusIntegrations: any[],
  proIntegrations: any[],
  activeCommunity: any,
  activeIntegrationsCount: number,
  hasFeatureAccess: boolean,
  isNoMoreUpgradeAvailable: boolean,
  isEnterpriseUser: boolean,
  isSuperUser: boolean,
  loading: boolean,
  selectedSection: string,
  partnerIdentifier: string,
  person: any,
  plan?: BillingPlan.BillingPlan,
  usageStats: UsageStats,
  availableFeatures: BillingPlan.BillingPlanDetailCode[]
}

class SettingsIntegrationsComponent extends React.Component<SettingsIntegrationsProps, SettingsIntegrationsState> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

    constructor(props: SettingsIntegrationsProps) {
    super(props)
    this.state = {
      data: null,
      activeIntegrations: [],
      availableIntegrations: [],
      enterpriseIntegrations: [],
      plusIntegrations: [],
      proIntegrations: [],
      activeCommunity: null,
      activeIntegrationsCount: 0,
      hasFeatureAccess: false,
      isNoMoreUpgradeAvailable: false,
      isEnterpriseUser: false,
      isSuperUser: false,
      loading: true,
      partnerIdentifier: DEFAULT_PARTNER_IDENTIFIER,
      person: null,
      selectedSection: IntegrationTabMenus.active,
      usageStats: enterpriseUsage,
      availableFeatures: [],
    }
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    Promise.all([
      this.sharedStore.getValue(SharedKeys.activeCommunity),
      this.sharedStore.getValue(SharedKeys.person),
    ])
    .then(([activeCommunity, person]) => {
      this.setState({activeCommunity, person})
      if (activeCommunity.billingInfo && activeCommunity.billingInfo.currentPlan) {
        this.setState({
          isNoMoreUpgradeAvailable: noMoreUpgradeAvailable(activeCommunity.billingInfo.currentPlan),
          isSuperUser: activeCommunity.role === Roles.super_admin,
          partnerIdentifier: activeCommunity.billingInfo.currentPlan.partner_identifier,
          plan: activeCommunity.billingInfo.currentPlan,
          usageStats: activeCommunity.billingInfo.usageStats,
          availableFeatures: activeCommunity.billingInfo.currentPlan.features || [],
          hasFeatureAccess: canAccessPlanFeature(BillingPlanDetailCode.MAX_INTEGRATIONS, activeCommunity.billingInfo.currentPlan)
            && BillingPlan.getMaximumNumberOfIntegrations(activeCommunity.billingInfo.currentPlan) !== 0
        })
      }
      this.loadCommunityIntegrations()
    })
  }

  public render() {
    return <>
      {this.state.loading && (<Loading size="32"/>)}
      {this.state.loading === false && <>
          <div className="settings-integrations-wrapper">
            <div className="header-container">
              <HeaderComponent
                subTitle={'Integration hub'}
                description={['Connect and manage all essential integrations']}
              />
              {this.renderIntegrationLimitContainer()}
            </div>
            {this.state.loading === false && <div>
              <TabMenu menus={this.integrationMenus()}
                       selectedItemKey={this.state.selectedSection}
                       onSelect={(item: MenuItem) => {
                         this.setState({
                           selectedSection: item.key
                         })
                       }}/>
            </div>}
            {!this.state.loading && this.renderIntegrationList()}
            <div className="clearfix"></div>
          </div>
      </>}
    </>
  }

  private integrationMenus = (): MenuItem[] => {
    return [
      {
        name: 'Active',
        key: IntegrationTabMenus.active,
      },
      {
        name: 'Available Integrations',
        key: IntegrationTabMenus.availableIntegrations,
      },
    ]
  }

  private renderIntegrationList = () => {
    return this.state.selectedSection === IntegrationTabMenus.active ?
        this.renderActiveIntegrationList() : this.renderAvailableIntegrationList()
  }

  private renderActiveIntegrationList = () => {
    return <div className="options-container">
      <div className="integration-category-head">
        <h1 className="integration-category-title">{this.state.activeIntegrations.length === 0 ? 'No ' : ''}Active Integrations</h1>
      </div>
      <div className="settings-integrations-system-list-wrapper">
        {this.state.activeIntegrations.length > 0 && (
            <div className="settings-integrations-system-list-sub-wrapper">
              {
                this.state.activeIntegrations.map((system: CommunitySystem, i) => {
                  return this.renderIntegrationItem(system, i)
                })
              }

            </div>
        )}
      </div>
    </div>
  }

  private renderAvailableIntegrationList = () => {
    return <div className="options-container">
      {
        this.state.availableIntegrations.length > 0 && <>
            <div className="integration-category-head">
              <h1 className="integration-category-title">Available with your plan</h1>
            </div>
            <div className="settings-integrations-system-list-wrapper">
              <div className="settings-integrations-system-list-sub-wrapper">
                {
                  this.state.availableIntegrations.map((system: CommunitySystem, i) => {
                    return this.renderIntegrationItem(system, i)
                  })
                }
              </div>
            </div>
          </>
      }
      {this.state.partnerIdentifier === DEFAULT_PARTNER_IDENTIFIER && this.renderIntegrationSections()}
    </div>
  }

  private renderIntegrationSections = () => {
    return Object.keys(IntegrationSections).map((key,) => {
      const sectionName = IntegrationSections[key]
      const sectionIntegrations = this.state[`${sectionName.toLowerCase()}Integrations`]
      return sectionIntegrations.length > 0 && <>
      <div className="integration-category-head">
        <h1 className="integration-category-title">{sectionName} integrations
        {sectionName === IntegrationSections.ENTERPRISE ?
          <a className='premium-badge' href={ENTERPRISE_UPGRADE_MAIL_TO}>
            <Upgrade svgwidth="20" svgheight="20"/> Contact Support
          </a> :
          <span className='premium-badge' onClick={this.sendToBilling}>
            <Upgrade svgwidth="20" svgheight="20"/> Upgrade to {sectionName}
          </span>
        }
        </h1>
        <p>Unlock a world of exclusive features and enhanced functionalities with our {sectionName.toLowerCase()} integration.</p>
      </div>
      <div className="settings-integrations-system-list-wrapper">
        <div className="settings-integrations-system-list-sub-wrapper">
          {
            sectionIntegrations.map((system: CommunitySystem, i) => {
              return this.renderIntegrationItem(system, i)
            })
          }
        </div>
      </div>
    </>
    })
  }

  protected renderIntegrationLimitContainer = () => {
    const isUnlimited: boolean = this.state.partnerIdentifier !== DEFAULT_PARTNER_IDENTIFIER || this.allowedIntegrationCount() === UNLIMITED_VALUE
    const currentActiveIntegrationsCount: number = this.state.activeIntegrationsCount
    const maxIntegrationsCount: number = this.allowedIntegrationCount()
    const isLimitReached: boolean = currentActiveIntegrationsCount >= maxIntegrationsCount
    const description: string = 'You\'ve reached your integrations limit, please upgrade your plan to add more.'

    return <UsageMeterComponent
      entity='integrations'
      isSuperUser={this.state.isSuperUser}
      isUnlimited={isUnlimited}
      isLimitReached={isLimitReached}
      currentCount={currentActiveIntegrationsCount}
      maxLimit={maxIntegrationsCount}
      source={GA.UpgradeSource.Integrations}
      progressText='Integrations Used'
      progressDescription={description}
      showContactToRasa={this.state.isNoMoreUpgradeAvailable || BillingPlan.isOverContactLimit(this.state.usageStats)}
    />
  }

  private statusBadge = (integration: CommunitySystem) => {
    const status = (!!integration.is_active && !this.isSiblingConnected(integration))
    const badgeText: string = status ? 'Connected' : 'Not Connected'
    return (
      <span className={`status-badge${status ? ' flag-on' : ''}`}>
        {
          !this.hasIntegrationAccess(integration) ? (this.isEnterpriseOnlyIntegration(integration)) ?
          <a href={`${ENTERPRISE_UPGRADE_MAIL_TO} - ${integration.name}`}>
            <Upgrade svgwidth="15" svgheight="15"/>
            Contact Support
          </a> :
          <>
          <Upgrade svgwidth="15" svgheight="15"/>
            Upgrade to {integration.systemMetadata.ui_section}
          </> : badgeText
        }
      </span>
    )
  }
  private renderIntegrationItem = (integration, index) => {
    const systemInfo = Integrations.systemInformation(integration.name)
    const isFeatureAvailable = systemInfo.status === Integrations.IntegrationStatus.available && integration.available
    const isDisconnected = integration.status === CommunitySystemStatus.Disconnected && !this.isSiblingDisconnected(integration)
    const description = systemInfo.twoWayStatus === IntegrationStatus.available
    || systemInfo.twoWayStatus === IntegrationStatus.notYet ?
      `The ${integration.display_name} integration is a ${systemInfo.twoWayStatus === IntegrationStatus.available ? 'two-way' : 'one-way'} sync` :
      ''

    return (
      <article key={index}
               className={`integration-single-item ${this.integrationConfigDisabledClassName(integration, !isFeatureAvailable)}`}
               onClick={() => {this.editIntegrationConfig(integration, systemInfo, !isFeatureAvailable)}}>
        <div className="integration-item-wrap">
          <div className="integration-logo-wrap">
            {systemInfo.selectIcon}
          </div>
          <div className="integration-detail-wrap">
            <div className="integration-title-wrap">
              <div className="integration-title">{integration.display_name}{integration.systemMetadata.is_full_integration ? ' - Rich' : ''}</div>
              {isFeatureAvailable && this.statusBadge(integration)}
            </div>
            {
              isFeatureAvailable ? <>
                <div className="integration-description">
                  <p>{description}</p>
                </div>
                {isDisconnected && <div className="integration-btn-wrap">
                    <button className="integration-button"
                            onClick={() => {this.editIntegrationConfig(integration, systemInfo, !isFeatureAvailable)}}>
                      Reconnect
                    </button>
                    <button className="integration-button"
                            onClick={stopPropagationThen(() =>
                              this.handleForget(integration.community_system_id, integration.community_identifier))}>
                      Forget
                    </button>
                  </div>}
              </> : <ComingSoon />
            }
          </div>
        </div>
      </article>
    )
  }

  private handleForget = (systemId, communityId) => {
    this.context.entityMetadata
    .getEntityObject('community_system')
    .then((entity) => {
      entity.setRecordId(communityId, systemId)
      entity.data.id = systemId
      entity.data.status = null
      entity.data.status_changed_date = formatDateTimeForLambda(new Date())
      return entity.save().then(() => {
        const integration = this.state.activeIntegrations
          .find((i) => i.community_system_id === systemId && i.community_identifier === communityId)
        integration.status = null
        const activeIntegrations = this.state.activeIntegrations
          .filter((i) => i !== integration)
        const availableIntegrations = [...this.state.availableIntegrations]
        availableIntegrations.push(integration)
        this.setState({
          activeIntegrations,
          availableIntegrations,
        })
        this.context.store.dispatch(Actions.closeBanner(BannerType.COMMUNITY_SYSTEM_DISCONNECTED))
      })
    })
  }

  private isEnterpriseOnlyIntegration(integration: CommunitySystem) {
    return integration.systemMetadata.ui_section === IntegrationSections.ENTERPRISE
  }

  private hasIntegrationAccess = (system: CommunitySystem) => {
    if (Utils.isUnrestrictedIntegration(system.name) || (isTruthy(system.connected) && (
      !this.isRichIntegration(system) || JSON.parse(system.system_api_attribute || '{}').is_full_integration))) {
      return true
    }
    if (!Utils.isPlanBasedIntegration(system.name) && this.state.activeIntegrationsCount >= this.allowedIntegrationCount() ||
      BillingPlan.isOverContactLimit(this.state.usageStats) ||
      (this.isRichIntegration(system) && !this.hasRichIntegrationAccess())) {
      return false
    }
    switch (system.name) {
      case IntegrationSystems.Instagram:
        return this.state.availableFeatures.length > 0 &&
          this.state.availableFeatures.indexOf(BillingPlan.BillingPlanDetailCode.INSTAGRAM_INTEGRATION) > -1
      case IntegrationSystems.YourMembership:
        return this.state.availableFeatures.length > 0 &&
          this.state.availableFeatures.indexOf(BillingPlan.BillingPlanDetailCode.YOUR_MEMBERSHIP_INTEGRATION) > -1
      case IntegrationSystems.Nimble:
        return this.state.availableFeatures.length > 0 &&
          this.state.availableFeatures.indexOf(BillingPlan.BillingPlanDetailCode.NIMBLE_INTEGRATION) > -1
      case IntegrationSystems.Fonteva:
        return this.state.availableFeatures.length > 0 &&
          this.state.availableFeatures.indexOf(BillingPlan.BillingPlanDetailCode.FONTEVA_INTEGRATION) > -1
      case IntegrationSystems.Imis:
        return this.state.availableFeatures.length > 0 &&
          this.state.availableFeatures.indexOf(BillingPlan.BillingPlanDetailCode.IMIS_INTEGRATION) > -1
      case IntegrationSystems.HigherLogicMagnetMail:
        return this.state.availableFeatures.length > 0 &&
          this.state.availableFeatures.indexOf(BillingPlan.BillingPlanDetailCode.MAGNET_MAIL_INTEGRATION) > -1
      case IntegrationSystems.Impexium:
        return this.state.availableFeatures.length > 0 &&
          this.state.availableFeatures.indexOf(BillingPlan.BillingPlanDetailCode.IMPEXIUM_INTEGRATION) > -1
      case IntegrationSystems.Informz:
        return this.state.availableFeatures.length > 0 &&
          this.state.availableFeatures.indexOf(BillingPlan.BillingPlanDetailCode.INFORMZ_INTEGRATION) > -1
      case IntegrationSystems.PersonifyMCPro:
        return this.state.availableFeatures.length > 0 &&
          this.state.availableFeatures.indexOf(BillingPlan.BillingPlanDetailCode.PERSONIFY_MC_PRO) > -1
      case IntegrationSystems.Zoom:
        return this.state.availableFeatures.length > 0 &&
          this.state.availableFeatures.indexOf(BillingPlan.BillingPlanDetailCode.ZOOM_INTEGRATION) > -1
      case IntegrationSystems.API:
        return this.state.availableFeatures.length > 0 &&
          this.state.availableFeatures.indexOf(BillingPlan.BillingPlanDetailCode.API_INTEGRATION_KEY) > -1
      case IntegrationSystems.GrowthZone:
        return this.state.availableFeatures.length > 0 &&
          this.state.availableFeatures.indexOf(BillingPlan.BillingPlanDetailCode.GROWTHZONE_INTEGRATION) > -1
      default:
        return true
    }
  }

  private allowedIntegrationCount = (): number => {
    return this.state.plan ?
      BillingPlan.getMaximumNumberOfIntegrations(this.state.plan) : 0
  }

  private integrationConfigDisabledClassName = (system: any, isUpgradeFormRequire: boolean) => {
    if (this.hasIntegrationAccess(system) && this.isSiblingConnected(system) && !this.isRichIntegration(system)) {
      return 'optionDisbaledItem'
    }
    if (Utils.isUnrestrictedIntegration(system.name)) {
      return 'integration-single-item-clickable'
    }
    return isUpgradeFormRequire && system.connected  === 1 ? '' : !isUpgradeFormRequire ? 'integration-single-item-clickable' : ''
  }

  private upgradeFormRequireClickable(system: any, isUpgradeFormRequire: boolean) {
    return !isUpgradeFormRequire || (Utils.isUnrestrictedIntegration(system.name)) ? true :
    isUpgradeFormRequire && system.connected === 1 ? true : false
  }

  private integrationConfigUrl = (cs: CommunitySystem) => {
    let integrationConfigUrl = cs.community_system_id
    ? `${INTEGRATIONS_CONFIG_URL}?id=${cs.community_system_id}`
    : `${INTEGRATIONS_CONFIG_URL}?systemName=${cs.name}&displayName=${cs.display_name}`
    if (cs.systemMetadata.is_full_integration) {
      integrationConfigUrl += `&isRichIntegration=${cs.systemMetadata.is_full_integration}`
    }
    return integrationConfigUrl
  }

  private sendToBilling = () => {
    this.props.push(DashboardMenuOption.billing + '?upgrade_from=' + GA.UpgradeSource.Integrations)
  }

  private editIntegrationConfig = (cs: CommunitySystem, info: Integrations.SystemInformation, isUpgradeFormRequire
    : boolean) => {
    if (this.upgradeFormRequireClickable(cs, isUpgradeFormRequire)) {
      if (!this.hasIntegrationAccess(cs) && cs.systemMetadata.ui_section !== IntegrationSections.ENTERPRISE) {
        this.sendToBilling()
      } else if (info.status === Integrations.IntegrationStatus.available && cs.available &&
          this.hasIntegrationAccess(cs)) {
        this.props.push(this.integrationConfigUrl(cs))
      }
    }
    return false
  }

  private loadCommunityIntegrations = () => {
    getCommunityIntegrations(this.state.activeCommunity, this.state.person)
    .then((integrationsResult: CommunitySystem[]) => {
      let filteredIntegrations = integrationsResult.filter((system: CommunitySystem) => {
        const info = Integrations.systemInformation(system.name)
        return !isNil(info.status) && info.status !== Integrations.IntegrationStatus.notYet
      })
      filteredIntegrations = orderBy(filteredIntegrations, ['name'], 'asc')
      this.props.datasetLoaded(filteredIntegrations)
      this.setState({
        data: filteredIntegrations,
        }, () => {
          const activeIntegrations = []
          const availableIntegrations = []
          const restOfTheIntegrations = {
            [IntegrationSections.PLUS]: [],
            [IntegrationSections.PRO]: [],
            [IntegrationSections.ENTERPRISE]: [],
          }
          filteredIntegrations.map((i) => {
            // when no community_system record exists in database: connected = 0 in the response.
            // is_active = 1 means a record is connected and in use
            const ui_section = i.systemMetadata.ui_section || IntegrationSections.PLUS
            if (isTruthy(i.connected) && (isTruthy(i.is_active) || i.status ===CommunitySystemStatus.Disconnected) && !this.isSiblingConnected(i) && (!this.isRichIntegration(i)
              || this.hasRichIntegrationAccess()) && (i.status !== CommunitySystemStatus.Disconnected || !this.isSiblingDisconnected(i))) {
              activeIntegrations.push(i)
            } else if (this.hasIntegrationAccess(i)) {
                availableIntegrations.push(i)
            } else {
              restOfTheIntegrations[ui_section].push(i)
            }
          })
          // by default load Available Integration tab when no active integration available
          if (activeIntegrations.length === 0) {
            this.setState({
              selectedSection: IntegrationTabMenus.availableIntegrations
            })
          }
          this.setState({
            ...this.state,
            activeIntegrationsCount: activeIntegrations.filter((i) => isTruthy(i.is_active)).length,
            activeIntegrations,
            availableIntegrations,
            proIntegrations: restOfTheIntegrations[IntegrationSections.PRO],
            plusIntegrations: restOfTheIntegrations[IntegrationSections.PLUS],
            enterpriseIntegrations: restOfTheIntegrations[IntegrationSections.ENTERPRISE],
            loading: false,
          })
        })
      })
  }

  private hasRichIntegrationAccess = () => {
    return this.state.availableFeatures.length > 0 &&
    this.state.availableFeatures.indexOf(BillingPlan.BillingPlanDetailCode.RICH_INTEGRATION) > -1
  }

  private isRichIntegration = (system: CommunitySystem) => {
    return system.systemMetadata.is_full_integration
  }

  private isSiblingConnected = (system: CommunitySystem): boolean => {
    // any system with the same name and with multiple cards. e.g. HubSpot, Salesforce, etc.
    if (this.state.data) {
      const sameSystems = this.state.data && this.state.data.filter((x) => x.name === system.name)
      if (sameSystems.length > 1) {
        const isSystemSupportsFullIntegration = this.isRichIntegration(system)
        return sameSystems.filter((x) => x.is_active && ((JSON.parse(x.system_api_attribute || '{}').is_full_integration) !== isSystemSupportsFullIntegration)).length > 0
      } else {
        return false
      }
    } else {
      return false
    }
  }

  private isSiblingDisconnected = (system) => {
    const isFullIntegration = system.systemMetadata.is_full_integration
    const systemApiAttributes = JSON.parse(system.system_api_attribute || '{}')
    const sameSystems = this.state.data.filter((x) => x.name === system.name)
    if (sameSystems.length > 1) {
      return this.state.data && this.state.data.filter((x) => x.name === system.name && x.status === CommunitySystemStatus.Disconnected &&
        systemApiAttributes.is_full_integration !== isFullIntegration && systemApiAttributes.is_full_integration ===
        JSON.parse(x.system_api_attribute || '{}').is_full_integration).length > 0
    }
    return false
  }
}

export const SettingsIntegrations = GenericRedux.registerNewDatasetComponent<CommunitySystem[]>(
  SettingsIntegrationsComponent, 'settings_integrations')
